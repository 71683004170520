var name = 'defaultPage';

page[name] = class PageClassName extends page.PageClass{





    // constructor

    constructor(){

        super();

        this.name = '';

    }





    // init

    init(ajaxBool = false){

        this.name = '';

        page.helpers.getElements();
        page.helpers.scrollSelector();



        // define page

        for(let i = 0; i < page.names.length; i++){
            if(page.default.classList.contains(page.names[i])){
                this.name = page.names[i];
            }
        }

        if(this.name.length > 0){
            page[this.name].init(ajaxBool);
        }



        // default js

        page.helpers.defaultJS();



        // buttons

        page.helpers.customButtons();



        // funcs

        let init = function(){},
            show = function(){},
            hide = function(){},
            destroy = function(){},
            addResizeEvent = function(){};

        if(this.name.length > 0){
            init = page[this.name].init;
            show = page[this.name].show;
            hide = page[this.name].hide;
            destroy = page[this.name].destroy;
            addResizeEvent = page[this.name].addResizeEvent;
        }



        // show

        if(ajaxBool){
            this.show(ajaxBool);
        }
        else{
            page.preloader.add({
                do: this.show.bind(this, ajaxBool)
            });
        }



        return {
            name: () => this.name,
            init: init.bind(this, ajaxBool),
            show: show.bind(this, ajaxBool),
            hide: hide.bind(this, ajaxBool),
            destroy: destroy.bind(this, ajaxBool),
            addResizeEvent: addResizeEvent.bind(this)
        };

    }





    // show

    show(ajaxBool = false){

        let timeout = 0;
        if(ajaxBool || v.mobile()){
            timeout = 125;
        }

        setTimeout(() => {

            if(page.view){
                page.view.seek();
            }

            if(this.name.length > 0){
                page[this.name].show(ajaxBool);
            }

        }, timeout);

    }





};

page[name] = new page[name];
page[name] = page[name].create();